<template>
    <div class="dx-theme-background-color">
        <slot />
    </div>
    <DxToast
        v-model:visible="modelToast.isVisible"
        v-model:type="modelToast.type"
        minWidth="100"
        width="auto"
        :displayTime="5000"
        contentTemplate="content"
    >
        <template #content>
            <div class="row align-center font-20 font-medium color-fff pr-2">
                <DxButton
                    :active-state-enabled="false"
                    :focus-state-enabled="false"
                    :hover-state-enabled="false"
                    styling-mode="contained"
                    :type="typeButton"
                    :icon="'mdi mdi-' + typeIconButton"
                    class="icon-toast"
                    width="48px"
                    height="48px"
                />
                {{ modelToast.message }}
            </div>
        </template>
    </DxToast>
</template>

<script>
import { DxButton, DxToast } from "devextreme-vue";
export default {
    components: {
        DxToast,
        DxButton,
    },
    data() {
        return {
            modelToast: {
                isVisible: false,
                message: "",
                type: "success",
            },
        };
    },
    computed: {
        typeButton() {
            let type = this.modelToast.type;

            let map = {
                success: "success",
                error: "danger",
                warning: "warning",
            };
            if (map.hasOwnProperty(type)) {
                return map[type];
            } else {
                return type;
            }
        },
        typeIconButton() {
            let type = this.modelToast.type;
            let map = {
                success: "check-circle-outline",
                error: "close-circle-outline",
                warning: "alert",
            };
            if (map.hasOwnProperty(type)) {
                return map[type];
            } else {
                return type;
            }
        },
    },
    mounted() {
        let self = this;
        this.emitter.on("onToast", (params) => {
            self.modelToast.isVisible = false;
            setTimeout(() => {
                self.modelToast = params;
            }, 50);
        });
    },
};
</script>

<style></style>
