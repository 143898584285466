
<template>
    <Application
        :mynav="menuNav"
        @DangXuat="DangXuat"
        PhienBan="0.0.211005.1"
        :propModelToast="modelToast"
        NameApp="Lệnh điện tử"
    >
        <template v-slot:default>
            <slot />
        </template>
    </Application>
</template>


<script>
import Application from "@sonphat/common-v1/components/application.vue";

export default {
    components: { Application },
    data() {
        return {
            menuNav: [
                {
                    id: "Home",
                    text: "Trang chủ",
                    icon: "home",
                    to: "/",
                    expanded: false,
                },
                // Báo cáo
                {
                    id: "BCTK",
                    text: "Báo cáo - Thống kê",
                    icon: "mdi mdi-chart-timeline-variant",
                    // external: true,
                    expanded: false,
                },
                {
                    id: this.$t("namePath.BaoCaoHoatDongXe"),
                    parent: "BCTK",
                    text: this.$t("namePath.BaoCaoHoatDongXe"),
                    icon: "",
                    to: "/Bao-Cao/Hoat-Dong-Xe",
                    expanded: false,
                },
                {
                    id: this.$t("namePath.BaoCaoSuDungLenh"),
                    parent: "BCTK",
                    text: this.$t("namePath.BaoCaoSuDungLenh"),
                    icon: "",
                    to: "/Bao-Cao/Su-Dung-Lenh",
                    expanded: false,
                },
                {
                    id: this.$t("namePath.BaoCaoTruyenTaiDuLieu"),
                    parent: "BCTK",
                    text: this.$t("namePath.BaoCaoTruyenTaiDuLieu"),
                    icon: "",
                    to: "/Bao-Cao/Truyen-Tai-Du-Lieu",
                    expanded: false,
                },
            ],
            openState: false,
            modelToast: {
                isVisible: false,
                message: "",
                type: "success",
            },
        };
    },
    methods: {
        DangXuat() {
            this.$Core.Auth.Logout();
        },
    },
    mounted() {
        let self = this;
        this.emitter.on("onToast", (params) => {
            self.modelToast.isVisible = false;
            setTimeout(() => {
                self.modelToast = params;
            }, 50);
        });
    },
};
</script>

<style scoped>
>>> .icon-toast .dx-icon {
    font-size: 36px;
}
>>> .icon-toast {
    position: relative;
    left: -16px;
}
</style>