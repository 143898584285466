export default {
    namespaced: true,
    state: () => ({
        DanhSachTuyen: [],
        ThongTinLenh: null,
    }),

    mutations: {
        NgayTrongThang: (state, data) => {
            state.NgayTrongThang = data;
        },
        ThongTinLenh: (state, data) => {
            state.ThongTinLenh = data;
        },
    },
    getters: {},

    actions: {
        async TraCuuLenh({ commit }, data) {
            let rs = await this.$Core.Api.LenhDienTu(
                this.$i18n.global.t("urlTraCuuLenh.TraCuuLenh"),
                data
            ).Get();

            if (rs.StatusCode == 200) {
                if (rs.Data.status) {
                    commit("ThongTinLenh", rs.Data.data);
                }
            }
            return rs;
        },
        async XemFileXml(store, payload) {
            store;
            try {
                const resp = await this.$Core.Api.LenhDienTu(
                    this.$i18n.global.t("urlTraCuuLenh.XemFileXml"),
                    payload
                )
                    .Config((c) => {
                        c.responseType = "blob";
                        c.validateStatus = () => true;
                    })
                    .Get();
                if (resp.StatusCode == 200 && resp.Data) {
                    const url = URL.createObjectURL(resp.Data);
                    return url;
                } else return null;
            } catch (error) {
                if (error.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw error;
            }
        },
        async TaiFileXml(store, payload) {
            store;
            try {
                const resp = await this.$Core.Api.LenhDienTu(
                    this.$i18n.global.t("urlTraCuuLenh.TaiFileXml"),
                    payload
                )
                    .Config((c) => {
                        c.responseType = "blob";
                        c.validateStatus = () => true;
                    })
                    .Get();
                if (resp.StatusCode == 200 && resp.Data) {
                    const url = URL.createObjectURL(resp.Data);
                    return url;
                } else return null;
            } catch (error) {
                if (error.message == "Network Error")
                    throw Error("Không kết nối được đến máy chủ!");
                throw error;
            }
        },
    },
};

function set(store, key, data) {
    store.commit("Set", {
        key: key,
        data: data,
    });
}
// function unset(store, key) {
//     store.commit("Unset", {
//         key: key
//     });
// }
