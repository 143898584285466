<template>
    <div class="content">
        <div class="pl-4 pt-4 pr-4 headerrow">
            <div class="header">
                <div class="row logo">
                    <div
                        @click="XacNhanDangXuatKhoiHeThong"
                        style="cursor: pointer"
                    >
                        <!-- <img src="../assets/images/logoTCDB.png"> -->
                        <img src="@/assets/images/logoTCDB.png" alt />
                    </div>
                    <div class="column">
                        <div class="noidung_logo">
                            <div class="font-20 font-bold TongCuc">
                                CỤC ĐƯỜNG BỘ VIỆT NAM
                            </div>
                            <div
                                class="font-14 font-bold text-xs-center directorate"
                            >
                                DIRECTORATE FOR ROADS OF VIETNAM
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="font-20 ho_tro" style="color: #13b000">
                    Liên hệ: 1900.599.870
                </div>
            </div>
        </div>

        <div class="TraCuuLenh">
            <div class="font-bold text" style="font-size: 32px">
                Tra cứu thông tin lệnh vận chuyển
            </div>
            <span class="font-16"
                >Tìm kiếm, tra cứu thông tin lệnh của đơn vị vận tải</span
            >
            <div class="row justify-center">
                <div style="position: relative" class="widthinput">
                    <DxRadioGroup
                        :items="priorities"
                        :value="optionTraCuu"
                        layout="horizontal"
                        :onValueChanged="onValueChanged"
                    />
                </div>
            </div>
            <div class="row justify-center">
                <DxValidationGroup
                    ref="formValidation"
                    class="row justify-center mt-2"
                >
                    <div style="position: relative" class="widthinput">
                        <DxTextBox
                            v-model="ThongTinTraCuu"
                            placeholder="Nhập thông tin"
                            styling-mode="outlined"
                            validationMessageMode="always"
                            width="100%"
                            class="textbox-outlinex"
                            :onEnterKey="TimKiemBienKiemSoat"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Thông tin không được bỏ trống"
                                />
                                <DxStringLengthRule
                                    :max="128"
                                    message="Phải nhỏ hơn hoặc bằng 128 ký tự"
                                />
                            </DxValidator>
                        </DxTextBox>
                        <DxButton
                            style="position: absolute; top: 6px; right: 8px"
                            key="mdi-XACNHAN"
                            type="default"
                            text="TÌM KIẾM"
                            :width="100"
                            styling-mode="contained"
                            @click="TimKiemBienKiemSoat"
                        />
                    </div>
                </DxValidationGroup>
            </div>
        </div>

        <div class="thong_tin_xe" v-if="TrangThaiThongTinLenh">
            <div class="mt-4">
                <div class="font-20 font-bold thong_tin_chuyen_di">
                    Thông tin chuyến đi
                </div>
                <div class="thong_tin_xe_thong_tin_chuyen_di">
                    <div class="thong_tin_chuyen_di_mobile">
                        <div class="font-16 row align-center mb-1 mt-2">
                            <div class="xs-3">Mã lệnh:</div>
                            <div
                                class="xs-9 right fontmedium color-primary mr-12"
                            >
                                <a
                                    style="text-decoration: underline"
                                    @click="
                                        XemFileXml(
                                            ThongTinLenh?.file_DinhKems || []
                                        )
                                    "
                                >
                                    {{ ThongTinLenh?.maLenhTruyenTai }}
                                </a>
                                <span class="color-000 ml-2">
                                    (Tải XML
                                    <DxButton
                                        type="default"
                                        styling-mode="text"
                                        icon="mdi mdi-download"
                                        @click="
                                            TaiFileXml(
                                                ThongTinLenh?.file_Xml || '',
                                                ThongTinLenh?.bienSoXeThucHien
                                            )
                                        "
                                    />
                                    )
                                </span>
                            </div>
                        </div>
                        <div class="font-16 row mb-1">
                            <div class="xs-3">Tên DNVT:</div>
                            <div class="xs-9 right fontmedium">
                                {{ ThongTinLenh?.donViVanTai }}
                            </div>
                        </div>
                        <div class="font-16 row mb-1">
                            <div class="xs-3">Mã tuyến:</div>
                            <div class="xs-9 right fontmedium">
                                {{ ThongTinLenh?.maTuyen }}
                            </div>
                        </div>
                        <div class="font-16 row mb-1">
                            <div class="xs-3">Bến đi -> Bến đến:</div>
                            <div class="xs-9 right fontmedium">
                                {{ ThongTinLenh?.tenBenDi }}
                                -
                                {{ ThongTinLenh?.tenBenDen }}
                            </div>
                        </div>
                    </div>
                    <div class="thong_tin_chuyen_di_phai">
                        <div class="font-16 row mb-1 mt-2">
                            <div class="xs-3">Giờ xuất bến:</div>
                            <div class="xs-9 right fontmedium">
                                {{ GioXuatBen }}
                            </div>
                        </div>
                        <div class="font-16 row mb-1">
                            <div class="xs-3">Số khách xuất bến:</div>
                            <div class="xs-9 right fontmedium">
                                {{ ThongTinLenh?.soKhachBenDi }}
                            </div>
                        </div>
                        <div class="font-16 row mb-1">
                            <div class="xs-3">Hình thức chạy:</div>
                            <div class="xs-9 right fontmedium">
                                {{ ThongTinLenh?.hinhThucChay }}
                            </div>
                        </div>
                        <div class="font-16 row mb-1">
                            <div class="xs-3">Hành trình tuyến:</div>
                            <div class="xs-9 right fontmedium">
                                {{ ThongTinLenh?.hanhTrinhChay }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt4 thong_tin_xe_thong_tin_chuyen_di xs12">
                <div class="xs12 ThongTinXe">
                    <div class="font-20 font-bold thong_tin_chuyen_di">
                        Thông tin xe
                    </div>
                    <div>
                        <div class="">
                            <div class="font-16 row mb-1 mt-2">
                                <div class="xs-5">
                                    {{
                                        ThongTinLenh?.bienSoXeDangKy !=
                                        ThongTinLenh?.bienSoXeThucHien
                                            ? "Biển số xe đi thay:"
                                            : "Biển số xe đăng ký:"
                                    }}
                                </div>
                                <div class="xs-7 right font-medium">
                                    {{ ThongTinLenh?.bienSoXeThucHien }}
                                </div>
                            </div>
                            <div class="font-16 row mb-1">
                                <div class="xs-5">
                                    {{
                                        ThongTinLenh?.bienSoXeDangKy !=
                                        ThongTinLenh?.bienSoXeThucHien
                                            ? "Số chỗ (đi thay):"
                                            : "Số chỗ:"
                                    }}
                                </div>
                                <div class="xs-7 right fontmedium">
                                    {{ ThongTinLenh?.trongTaiXeThucHien }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="xs12 thong-tin-lai-xe">
                    <div class="font-20 font-bold thong_tin_chuyen_di">
                        Thông tin lái xe
                    </div>
                    <div>
                        <div class="ThongTinXe">
                            <div
                                class="font-16 row mb-1 mt-2"
                                v-for="(
                                    item, index
                                ) in ThongTinLenh?.danhSachLaiXe || []"
                                :key="index"
                            >
                                <div class="xs3">Lái xe {{ index + 1 }}:</div>
                                <div class="xs9 right fontmedium">
                                    {{ item?.hoTen }} - {{ item?.soGPLX }} -
                                    {{ item?.hangGPLX }}
                                </div>
                            </div>

                            <div
                                class="font-16 row mb-1 mt-2"
                                v-for="(
                                    item, index
                                ) in ThongTinLenh?.danhSachNhanVienPhucVu || []"
                                :key="index"
                            >
                                <div class="xs3">Phục vụ xe:</div>
                                <div class="xs9 right fontmedium">
                                    {{ item?.hoTen }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-4 thong_tin_xe_thong_tin_chuyen_di xs12">
                <div class="xs12 ThongTinXe">
                    <div class="font-20 font-bold thong_tin_chuyen_di">
                        Thông tin giấy tờ xe
                    </div>
                    <div>
                        <div class="ThongTinXe">
                            <div class="font-16 row mb-1 mt-2">
                                <div class="xs-4">Hạn phù hiệu tuyến:</div>
                                <div class="xs-8 fontmedium right">
                                    {{ HanPhuHieuTuyen }}
                                </div>
                            </div>
                            <div class="font-16 row mb-1">
                                <div class="xs-4">Hạn đăng kiểm:</div>
                                <div class="xs-8 fontmedium right">
                                    {{ HanDangKiem }}
                                </div>
                            </div>
                            <div class="font-16 row mb-1">
                                <div class="xs-4">Hạn bảo hiểm:</div>
                                <div class="xs-8 fontmedium right">
                                    {{ HanBaoHiem }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="xs12 thong-tin-lai-xe">
                    <div class="font-20 font-bold thong_tin_chuyen_di">
                        Thông tin ký lệnh
                    </div>
                    <div>
                        <div class="">
                            <div class="font-16 row mb-1 mt-2">
                                <div class="xs-4">Doanh nghiệp vận tải ký:</div>
                                <div class="xs-8 fontmedium right">
                                    {{ DoanhNghiepVanTaiKy }}
                                </div>
                            </div>
                            <div
                                class="font-16 row mb-1 mt-2 ngan_cach_thong__tin"
                            >
                                <div class="xs-4">Ký bởi:</div>
                                <div class="xs-8 fontmedium right">
                                    {{ ThongTinLenh?.doanhNghiep_KyBoi }}
                                </div>
                            </div>
                            <div class="font-16 row mb-1">
                                <div class="xs-4">Bến đi ký:</div>
                                <div class="xs-8 fontmedium right">
                                    {{ BenDiKy }}
                                </div>
                            </div>
                            <div
                                class="font-16 row mb-1 mt-2 ngan_cach_thong__tin"
                            >
                                <div class="xs-4">Ký bởi:</div>
                                <div class="xs-8 fontmedium right">
                                    {{ ThongTinLenh?.benDi_KyBoi }}
                                </div>
                            </div>
                            <div class="font-16 row mb-1">
                                <div class="xs-4">Bến đến ký:</div>
                                <div class="xs-8 fontmedium right">
                                    {{ BenDenKy }}
                                </div>
                            </div>
                            <div class="font-16 row mb-1 mt-2">
                                <div class="xs-4">Ký bởi:</div>
                                <div class="xs-8 fontmedium right">
                                    {{ ThongTinLenh?.benDen_KyBoi }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <DxPopup
        style="position: relative"
        v-model:visible="PopupXemFile"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="false"
        position="center"
        width="572px"
        maxWidth="calc(100vw - 24px)"
        height="calc(100vh - 96px)"
    >
        <!-- :animation="animation" -->
        <template #content>
            <!-- <DxScrollView width="100%" height="100%"> xxxxxx </DxScrollView> -->
            <DxGallery
                :dataSource="JSON.parse(JSON.stringify(ChiTiet.DanhSachFile))"
                :loop="true"
                ref="DanhSachFile"
                :slideshow-delay="0"
                :show-nav-buttons="
                    ChiTiet.DanhSachFile.length > 1 ? true : false
                "
                :show-indicator="true"
                :onSelectionChanged="onSelectionChanged"
                width="100%"
                height="calc(100vh - 96px - 48px - 48px)"
            >
                <template #item="{ data }">
                    <div
                        class="row align-center"
                        style="width: 100%; height: 100%"
                    >
                        <iframe
                            :src="`${data}#view=Fit`"
                            loading="lazy"
                            style="width: 100%; height: 100%"
                        />
                    </div>
                </template>
            </DxGallery>

            <div class="row justify-end">
                <DxButton
                    class="mt-3"
                    type="default"
                    text="Đóng"
                    styling-mode="contained"
                    @click="PopupXemFile = false"
                />
            </div>
        </template>
    </DxPopup>

    <DxPopup
        v-model:visible="popupDangXuat"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="false"
        :width="440"
        maxWidth="calc(100vw - 24px)"
        height="auto"
    >
        <NotificationVue :Params="ParamsDangXuat" @click="XacNhanDangXuat" />
    </DxPopup>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import DxRadioGroup from "devextreme-vue/radio-group";
import NotificationVue from "@sonphat/common-v1/components/Notification";
import {
    DxButton,
    DxTextBox,
    DxValidationGroup,
    DxGallery,
    DxPopup,
} from "devextreme-vue";
import {
    DxValidator,
    DxRequiredRule,
    // DxEmailRule,
    // DxPatternRule,
    DxStringLengthRule,
} from "devextreme-vue/validator";

export default {
    data() {
        return {
            popupDangXuat: false,
            ParamsDangXuat: {
                state: "Warning",
                button_save: "Đồng ý",
                button_cancel: "Hủy",
                title: "Cảnh báo!",
                message_title: "Bạn muốn thoát khỏi hệ thống?",
            },
            PopupXemFile: false,

            optionTraCuu: "Biển số",
            priorities: ["Biển số", "Mã CDBVN"],
            TrangThaiThongTinLenh: false,
            ThongTinTraCuu: null,
            ChiTiet: {
                DanhSachFile: [],
            },
        };
    },
    components: {
        DxTextBox,
        DxButton,
        DxValidationGroup,
        DxValidator,
        DxRadioGroup,
        DxRequiredRule,
        DxStringLengthRule,
        DxGallery,
        DxPopup,
        NotificationVue,
    },
    computed: {
        ...mapState({
            ThongTinLenh: (state) => state.TraCuuLenhDienTu.ThongTinLenh,
        }),
        GioXuatBen() {
            if (this.ThongTinLenh?.gioXuatBenKeHoach) {
                return moment(this.ThongTinLenh.gioXuatBenKeHoach).format(
                    "HH:mm DD/MM/yyyy"
                );
            }
            return "";
        },
        HanPhuHieuTuyen() {
            if (this.ThongTinLenh?.hanPhuHieu) {
                return moment(this.ThongTinLenh.hanPhuHieu).format(
                    "DD/MM/yyyy"
                );
            }
            return "";
        },
        HanDangKiem() {
            if (this.ThongTinLenh?.hanDangKiem) {
                return moment(this.ThongTinLenh.hanDangKiem).format(
                    "DD/MM/yyyy"
                );
            }
            return "";
        },
        HanBaoHiem() {
            if (this.ThongTinLenh?.hanBaoHiem) {
                return moment(this.ThongTinLenh.hanBaoHiem).format(
                    "DD/MM/yyyy"
                );
            }
            return "";
        },
        DoanhNghiepVanTaiKy() {
            if (this.ThongTinLenh?.doanhNghiep_ThoiGianKy) {
                return moment(this.ThongTinLenh.doanhNghiep_ThoiGianKy).format(
                    "HH:mm DD/MM/yyyy"
                );
            }
            return "";
        },
        BenDiKy() {
            if (this.ThongTinLenh?.benDi_ThoiGianKy) {
                return moment(this.ThongTinLenh.benDi_ThoiGianKy).format(
                    "HH:mm DD/MM/yyyy"
                );
            }
            return "";
        },
        BenDenKy() {
            if (this.ThongTinLenh?.benDen_ThoiGianKy) {
                return moment(this.ThongTinLenh.benDen_ThoiGianKy).format(
                    "HH:mm DD/MM/yyyy"
                );
            }
            return "";
        },
    },

    methods: {
        XacNhanDangXuatKhoiHeThong() {
            this.popupDangXuat = true;
        },
        XacNhanDangXuat(param) {
            if (param == true) {
                this.popupDangXuat = false;
                this.$Core.Auth.Logout();
            } else if (param == false) {
                this.popupDangXuat = false;
            }
        },
        async TaiFileXml(e, BienSo) {
            try {
                this.ChiTiet.DanhSachFile = [];
                this.$startLoading;

                let rs = await this.$store.dispatch(
                    "TraCuuLenhDienTu/TaiFileXml",
                    {
                        path: e,
                    }
                );
                if (rs) {
                    this.$Helper.BrowserDownloadLink(
                        rs,
                        `Lệnh điện tử ${BienSo}.xml`
                    );
                    console.log("rs", rs);
                }
                this.$stopLoading;
            } catch (error) {
                this.$stopLoading;
            }
        },

        async XemFileXml(e) {
            try {
                this.ChiTiet.DanhSachFile = [];
                this.$startLoading;
                e.forEach(async (item) => {
                    let rs = await this.$store.dispatch(
                        "TraCuuLenhDienTu/XemFileXml",
                        {
                            path: item,
                        }
                    );
                    if (rs) {
                        this.ChiTiet.DanhSachFile.push(rs);
                    }
                });
                setTimeout(() => {
                    this.$refs.DanhSachFile.instance.repaint();
                }, 100);
                this.PopupXemFile = true;
                this.$stopLoading;
            } catch (error) {
                this.$stopLoading;
            }
        },
        onValueChanged(e) {
            this.optionTraCuu = e.value;
        },
        validate() {
            return this.$refs.formValidation.instance.validate();
        },
        async TimKiemBienKiemSoat() {
            this.TrangThaiThongTinLenh = false;
            if (!this.validate().isValid) {
                this.$Helper.autoFocusError(this.validate());
            } else {
                try {
                    this.$startLoading;
                    let res = await this.$store.dispatch(
                        "TraCuuLenhDienTu/TraCuuLenh",
                        {
                            BienKiemSoat:
                                this.optionTraCuu == "Biển số"
                                    ? this.ThongTinTraCuu
                                    : "",
                            MaLenhCapPhat:
                                this.optionTraCuu == "Mã CDBVN"
                                    ? this.ThongTinTraCuu
                                    : "",
                        }
                    );
                    this.$stopLoading;
                    if (!res.Data.status) {
                        let modelToast = {
                            isVisible: true,
                            message: res.Data.message,
                            type: "error",
                        };
                        this.emitter.emit("onToast", modelToast);
                        this.TrangThaiThongTinLenh = false;
                    } else {
                        this.TrangThaiThongTinLenh = true;
                    }
                } catch (error) {
                    console.log("error", error);
                    this.$stopLoading;
                }
            }
        },
    },
};
</script>

<style scoped>
.TraCuuLenh {
    text-align: center;
}
.widthinput {
    width: 1200px;
}
.thong_tin_chuyen_di {
    padding-bottom: 5px;
    border-bottom: 1px solid #9e9e9e;
}
.ngan_cach_thong__tin {
    padding-bottom: 5px;
    border-bottom: 1px solid #9e9e9e;
}
.thong_tin_xe {
    width: 1200px;
    margin: auto;
}
.noi_dung_ttcd {
    width: 130px;
    display: inline-block;
}
.xs-3 {
    flex-basis: 25%;
    flex-grow: 0;
    max-width: 25%;
}
.xs-4 {
    flex-basis: 33.33333333333333%;
    flex-grow: 0;
    max-width: 33.33333333333333%;
}
.xs-8 {
    flex-basis: 66.66666666666666%;
    flex-grow: 0;
    max-width: 66.66666666666666%;
}
.xs-5 {
    flex-basis: 41.66666666666667%;
    flex-grow: 0;
    max-width: 41.66666666666667%;
}
.xs-7 {
    flex-basis: 58.333333333333336%;
    flex-grow: 0;
    max-width: 58.333333333333336%;
}
.xs-9 {
    flex-basis: 75%;
    flex-grow: 0;
    max-width: 75%;
}
.noi_dung_ttcdr {
    width: 150px;
    display: inline-block;
}
.header {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}
.headerrow,
.thong_tin_xe_thong_tin_chuyen_di {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    justify-content: space-between;
    padding-right: 16px;
}
.ThongTinXe {
    padding-right: 16px;
}
.header img {
    width: 73px;
    height: 73px;
}
.noidung_logo {
    line-height: 36px;
}
.thong_tin_chuyen_di_mobile {
    width: 50%;
    /* padding: 0 16px 0; */
}
.thong_tin_chuyen_di_phai {
    width: 50%;
    padding-left: 16px;
}

.thong-tin-lai-xe {
    padding-left: 16px;
}
.mt4 {
    margin-top: 24px;
}
@media screen and (min-width: 360px) and (max-width: 1200px) {
    .headerdes {
        display: flex;
        flex-direction: column;
    }
    .mt4 {
        margin-top: 0;
    }
    .header img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }
    .ThongTinXe {
        padding-right: 0;
    }
    .header .TongCuc {
        font-size: 16px !important;
    }
    .header .directorate {
        text-align: center;
        font-size: 12px !important;
    }
    .headerrow,
    .thong_tin_xe_thong_tin_chuyen_di {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        padding-right: 0;
    }
    .header .logo {
        justify-content: center;
    }
    .ho_tro {
        font-size: 16px !important;
        text-align: center;
    }
    .noidung_logo {
        line-height: 25px;
    }
    .TraCuuLenh .text {
        font-size: 20px !important;
        margin-top: 10px;
    }
    .widthinput {
        width: 100%;
    }
    .thong_tin_chuyen_di {
        padding-bottom: unset;
        border-bottom: unset;
        font-size: 16px !important;
        margin-top: 16px;
    }
    .thong_tin_xe {
        width: 100%;
    }
    .thong_tin_chuyen_di_mobile {
        width: 100%;
        padding: 0;
    }
    .thong_tin_chuyen_di_phai {
        width: 100%;
        padding-left: 0;
    }
    .content {
        padding: 8px;
    }

    .right {
        text-align: right;
    }
    .xs-3 {
        flex-basis: 40%;
        flex-grow: 0;
        max-width: 40%;
    }
    .xs-9 {
        flex-basis: 60%;
        flex-grow: 0;
        max-width: 60%;
    }
    .xs-5 {
        flex-basis: 60%;
        flex-grow: 0;
        max-width: 60%;
    }
    .xs-7 {
        flex-basis: 40%;
        flex-grow: 0;
        max-width: 40%;
    }
    .xs-4 {
        flex-basis: 50%;
        flex-grow: 0;
        max-width: 50%;
    }
    .xs-8 {
        flex-basis: 50%;
        flex-grow: 0;
        max-width: 50%;
    }
    .thong-tin-lai-xe {
        padding-left: 0;
    }
    .fontmedium {
        font-weight: 500 !important;
    }
}
</style>
>
