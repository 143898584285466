import { createRouter, createWebHistory } from "vue-router";

import Home from "./views/home";
import TraCuu from "./views/Tra-Cuu/index";
// import defaultLayout from "./layouts/side-nav-inner-toolbar";
import menuApplication from "./layouts/menu-application";
import none from "./layouts/none";
// import simpleLayout from "./layouts/single-card";

// function loadView(view) {
//     return () => import(`./views/${view}.vue`);
// }
// function loadViewIndex(view) {
//     return () => import(`./views/${view}/index.vue`);
// }

export default function ($Core) {
    const router = new createRouter({
        routes: [
            // {
            //     path: "/",
            //     name: "home",
            //     meta: {
            //         requiresAuth: true,
            //         layout: menuApplication
            //     },
            //     component: Home
            // },
            // {
            //     path: "/Bao-Cao/Truyen-Tai-Du-Lieu/Chi-Tiet",
            //     name: "ChiTietBaoCaoTruyenTaiDuLieu",
            //     meta: {
            //         requiresAuth: false,
            //         layout: menuApplication,
            //         title: "Chi tiết báo cáo truyền tải dữ liệu"
            //     },
            //     component: loadViewIndex("Bao-Cao/Truyen-Tai-Du-Lieu/Chi-Tiet")
            // },

            {
                path: "/",
                name: "TraCuu",
                meta: {
                    requiresAuth: false,
                    layout: none,
                    title: "Trang Chu",
                },
                component: TraCuu,
            },
            // {
            //     path: "/recovery",
            //     redirect: "/home",
            // },
            // {
            //     path: "/:pathMatch(.*)*",
            //     redirect: "/home",
            // },
        ],
        history: createWebHistory(),
    });

    router.beforeEach((to, from, next) => {
        console.log("abcd", $Core);
        if (to.name === "login-form" && $Core.Auth.IsAuth) {
            next({ name: "home" });
        }

        if (!$Core.Auth.IsAuth) {
            $Core.Auth.Login();
            // next({
            //     name: "login-form",
            //     query: { redirect: to.fullPath },
            // });
        } else {
            next();
        }
    });
    return router;
}
